import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import '../styles/Gallery.scss'; // Import your CSS file for styling
import { jwtDecode } from 'jwt-decode';
import Cookies from "universal-cookie";
const cookies = new Cookies();

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function Gallery({ galleryData, onClick, dispatch }) {
  const [hovered, setHovered] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = galleryData.imageSrc; 
  const intervalRef = useRef(null); 
  const [admin, setAdmin] = useState(false); // State to store admin status
  console.log(galleryData)
  useEffect(() => {
    const token = cookies.get('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken && decodedToken.userAdmin) {
          setAdmin(decodedToken.userAdmin);
        }
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
    startImageRotation();
  };

  const handleMouseLeave = () => {
    setHovered(false);
    clearInterval(intervalRef.current);
  };

  const startImageRotation = () => {
    intervalRef.current = setInterval(() => {
      setCurrentImageIndex((prevIndex) => {
        let newIndex = Math.floor(Math.random() * images.length);
        while (newIndex === prevIndex) {
          newIndex = Math.floor(Math.random() * images.length);
        }
        return newIndex;
      });
    }, 3000); // Adjust the interval time as needed (in milliseconds)
  };

  const handleClick = () => {
    console.log("Clicked gallery number:", galleryData.id);
    onClick();
  };

  const handleDeleteGallery = async () => {
    try {
      const response = await axios.delete(`${backendUrl}/galleries/${galleryData.id}`);
      if (response.status === 200) {
        console.log(`Gallery with ID ${galleryData.id} deleted successfully`);
        // Optionally, update state or perform any necessary UI updates after deletion
      } else {
        console.error(`Failed to delete gallery with ID ${galleryData.id}`);
      }
    } catch (error) {
      console.error('Error deleting gallery:', error);
    }
  };

  return (
    <div className="gallerycomponent" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="image-container">
        <img src={galleryData.imageSrc} alt="Gallery Image" className="gallery-image" onClick={handleClick} />
      </div>
      <div className="title-container">
        <div className="title-rectangle">
          <p className="title-text">{galleryData.title}</p>
          <p className="description-text">{galleryData.description}</p>
        </div>
        {admin && (
          <button className="delete-button" onClick={handleDeleteGallery}>
            Delete Gallery
          </button>
        )}
      </div>
    </div>
  );
}

export default Gallery;

import React from 'react';
import '../styles/PostPage.scss';
import PostItem from '../components/PostItem';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const defaultTheme = createTheme();

const PostPage = ({ posts }) => {
  if (!posts) {
    return <div>Loading...</div>; // Loading indicator
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          height: '100%',
          backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className="entire">
          <div className="posts-container">
            {posts.map((post) => (
              <PostItem
                key={post.id}
                photo={post.photo}
                name={post.name}
                description={post.description}
                first_name={post.author_first_name}
                last_name={post.author_last_name}
                username={post.author_username}
                date={post.date_updated}
              />
            ))}
          </div>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default PostPage;

import "../styles/listings.scss";
import ListingItem from "../components/ListingItem";
import React, { useState } from "react";
import Button from '@mui/material/Button'; // Import Button component from Material-UI
import { jwtDecode } from 'jwt-decode';
import NewListingModal from "../components/NewListingModal";
import ListingModal from "../components/ListingModal"; // Import the ListingModal component
import Cookies from "universal-cookie";
const cookies = new Cookies();

const Listings = ({ state }) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State for NewListingModal
  const [selectedListing, setSelectedListing] = useState(null); // State for selected listing
  const token = cookies.get('token');
  const decodedToken = token ? jwtDecode(token) : null;
  const user_id = decodedToken ? decodedToken.userId : null;

  const handleAddListing = () => {
    setIsModalOpen(true); // Open the NewListingModal when Add Listing button is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the NewListingModal
  };

  const handleOpenListingModal = (listing) => {
    setSelectedListing(listing); // Set the selected listing
    console.log("firing")
  };

  const handleCloseListingModal = () => {
    setSelectedListing(null); // Close the ListingModal
  };

  return (
    <div className="page-container">
      <div className="header">
        <div className="emptylistingdiv"></div>
         {/* Button to add a new listing */}
         
        {state.listingData.map((listing, index) => (
          <ListingItem
            className="listingitem"
            key={index}
            listing={listing}
            onClick={() => handleOpenListingModal(listing)} // Open ListingModal on click
          />
        ))}
        <div className="vertical-bar">
          <h3>Classifieds</h3>
          <p>Ads will be active for 30 days after which the post will expire.</p>
          <p>Ads are only visible to club members.</p>
          <p>This page is provided as a convenience to club members. It is not moderated.</p>
          {decodedToken && (
            <Button 
              className="addlistingbutton"
              variant="contained" 
              onClick={handleAddListing}
            >
              Add Listing
            </Button>
          )}
        </div>
      </div>
      
      {/* Modal component (conditional rendering based on isModalOpen state) */}
      {isModalOpen && (
        <div className="modal">
          <NewListingModal onClose={handleCloseModal} />
        </div>
      )}

      {/* ListingModal component */}
      {selectedListing && (
        <ListingModal
          open={!!selectedListing}
          handleClose={handleCloseListingModal}
          listing={selectedListing}
        />
      )}
    </div>
  );
}

export default Listings;

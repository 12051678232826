
import { jwtDecode } from 'jwt-decode';

import "../styles/login.scss";
import React, { useState } from "react";
import axios from "axios";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Cookies from "universal-cookie";
import { useDropzone } from 'react-dropzone';

const cookies = new Cookies();
const backendUrl = process.env.REACT_APP_BACKEND_URL;

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        MUI component reworked
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function AdminGallery(props) {
  const [galleryDescription, setGalleryDescription] = useState('');
  const [galleryName, setGalleryName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const { dispatch, state, setDescription } = props;
  const token = cookies.get('token');
  const decodedToken = jwtDecode(token);
  const user_id = decodedToken.userId;
  
  const [images, setImages] = useState([]);
  const [active, setActive] = useState(false);

  const onDrop = (acceptedFiles) => {
    setImages(acceptedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (buttonName) => {
    handleMenuClose();
    dispatch({ type: "SET_ADMIN_PAGE_STATE", payload: buttonName });
    console.log("adminpage", state.adminPage);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const description = galleryDescription;
      const name = galleryName;

      // Check if post_name and post_description are correctly set
      console.log("Post Name:", name);
      console.log("Post Description:", description);

      if (!name.trim() || !description.trim()) {
        alert("Please fill out all required fields (post name and description)");
        return;
      }

      const formData = new FormData();
      formData.append('name', name);
      formData.append('description', description);
      formData.append('user_id', user_id);
      formData.append('active', active);
      if (images.length > 0) {
        formData.append('image', images[0]);
      }

      // Log formData values
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      fetch(`${backendUrl}/galleries`, {
      method: 'POST',
      body: formData,
    })
    } catch (error) {
      console.error("Error creating Post:", error);
      alert("Post creation failed!");
    }
  };

  const handleDelete = async () => {
    try {
      const user_id = cookies.get("user_id");
      const response = await axios.delete(`${backendUrl}/register`, {
        data: { id: user_id }
      });
      alert("User deleted successfully!");
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("Failed to delete user. Please try again.");
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          width: '80%',
          height: '100%',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Button
          onClick={handleMenuOpen}
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            backgroundColor: 'linear-gradient(to right, #ff0000, #00ff00)',
            width: '25%',
          }}
        >
          Select Action
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{ width: '100%' }}
        >
          <MenuItem
            variant="contained"
            sx={{ mt: 3, mb: 2, width: '100%' }}
            onClick={() => handleItemClick('Events')}
          >
            Events
          </MenuItem>
          <MenuItem
            variant="contained"
            sx={{ mt: 3, mb: 2, width: '100%' }}
            onClick={() => handleItemClick('Posts')}
          >
            Posts
          </MenuItem>
          <MenuItem
            variant="contained"
            sx={{ mt: 3, mb: 2, width: '100%' }}
            onClick={() => handleItemClick('Other')}
          >
            Users
          </MenuItem>
          <MenuItem
            variant="contained"
            sx={{ mt: 3, mb: 2, width: '100%' }}
            onClick={() => handleItemClick('Photos')}
          >
            Photos
          </MenuItem>
          <MenuItem
            variant="contained"
            sx={{ mt: 3, mb: 2, width: '100%' }}
            onClick={() => handleItemClick('Galleries')}
          >
            Galleries
          </MenuItem>
        </Menu>
        <Typography component="h1" variant="h5">
          Add A Gallery
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextField
            margin="normal"
            required
            id="post_name"
            label="Post Name"
            name="post_name"
            value={galleryName}
            onChange={(e) => setGalleryName(e.target.value)}
            placeholder="Enter post name"
            sx={{ width: '100%' }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="postdescription"
            label="Enter a new post"
            name="postdescription"
            autoComplete="postdescription"
            autoFocus
            type="postdescription"
            value={galleryDescription}
            onChange={(e) => setGalleryDescription(e.target.value)}
            placeholder="Enter a new post"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
                name="active"
                color="primary"
              />
            }
            label="Active"
          />
          <div {...getRootProps()} style={{ border: '1px dashed #ccc', padding: '10px', width: '100%', textAlign: 'center' }}>
            <input {...getInputProps()} />
            {images.length > 0 ? (
              <p>{images[0].name}</p>
            ) : (
              <p>Drag 'n' drop some files here, or click to select files</p>
            )}
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: 'linear-gradient(to right, #ff0000, #00ff00)',
              width: '100%',
            }}
          >
            Add a Post
          </Button>
        </Box>
        
        <Copyright sx={{ mt: 5 }} />
      </Box>
    </ThemeProvider>
  );
}

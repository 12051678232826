import React from 'react';
import '../styles/PhotoListItem.scss';
import PhotoDetailsModal from './PhotoDetailsModal';

const PhotoListItem = ({ photo, setModalPhoto }) => {


  
  return (
    <div className="photo-list__item">
      <img
        onClick={() => setModalPhoto(photo)}
        className="photo-list__image"
        src={photo.url}
        alt="Photo"
      />
    </div>
  );
};

export default PhotoListItem;

import React, { useEffect, useState } from 'react';
import EmailForm from "../components/EmailForm";
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import backgroundImage1 from '../assets/photo-78.jpg';
import backgroundImage2 from '../assets/photo-83.jpg';
import backgroundImage3 from '../assets/photo-94.jpg';
import backgroundImage4 from '../assets/photo-75.jpg';
import backgroundImage5 from '../assets/photo-99.jpg';
import backgroundImage6 from '../assets/photo-82.jpg';
import backgroundImage7 from '../assets/photo-88.jpg';
import backgroundImage8 from '../assets/photo-92.jpg';
import backgroundImage9 from '../assets/photo-77.jpg';
import backgroundImage10 from '../assets/photo-79.jpg';
import backgroundImage11 from '../assets/photo-84.jpg';
import backgroundImage12 from '../assets/photo-85.jpg';
import backgroundImage13 from '../assets/photo-86.jpg';
import backgroundImage14 from '../assets/photo-98.jpg';

const ContactPage = ({ state, dispatch }) => {
  const defaultTheme = createTheme();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(null);
  const [fade, setFade] = useState(false);

  const images = [
    backgroundImage1,
    backgroundImage2,
    backgroundImage3,
    backgroundImage4,
    backgroundImage5,
    backgroundImage6,
    backgroundImage7,
    backgroundImage8,
    backgroundImage9,
    backgroundImage10,
    backgroundImage11,
    backgroundImage12,
    backgroundImage13,
    backgroundImage14,
  ];

  useEffect(() => {
    const setRandomImageIndex = () => {
      const randomIndex = Math.floor(Math.random() * images.length);
      setCurrentImageIndex(randomIndex);
    };

    setRandomImageIndex();

    const interval = setInterval(() => {
      const nextIndex = (currentImageIndex + 1) % images.length;
      setNextImageIndex(nextIndex);
      setFade(true);
    }, 7500);

    return () => clearInterval(interval);
  }, [currentImageIndex, images.length]);

  useEffect(() => {
    if (fade && nextImageIndex !== null) {
      const img = new Image();
      img.src = images[nextImageIndex];
      img.onload = () => {
        setCurrentImageIndex(nextImageIndex);
        setFade(false);
        setNextImageIndex(null);
      };
    }
  }, [fade, nextImageIndex, images]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          minHeight: '100vh',
          backgroundImage: `url(${images[currentImageIndex]})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: fade ? 'none' : 'background-image 2s cubic-bezier(0.25, 0.1, 0.25, 1)',
          flexGrow: 1,
        }}
      >
        <div>
          <EmailForm />
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default ContactPage;

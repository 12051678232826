import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import logo from '../assets/SeatonValley.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch, faTimes, faEnvelope, faPhone, faFacebook, faTwitter } from '@fortawesome/free-solid-svg-icons';
import { loginHeight } from './logincomponentnav';
import { LoginComponent } from './logincomponentnav';
import { LOGIN_HEIGHT } from './logincomponentnav';
import { Link } from 'react-router-dom';
import Cookies from "universal-cookie";
import { jwtDecode } from "jwt-decode";
const backendUrl = process.env.REACT_APP_BACKEND_URL;




const NAV_HEIGHT = 10;


const NavbarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #333333;
  

  
`;

const NavbarContainer = styled.nav`
  display: flex;
  
  justify-content: space-between;
  align-items: center;
  background-color: #333333;
  height: ${NAV_HEIGHT}vh;
  padding: 0 2rem;
  color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #333333;
  }

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #333333;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 100%;
    background-color: rgba(51, 51, 51, 0.7);
    position: absolute;
    top: calc(${NAV_HEIGHT + LOGIN_HEIGHT + 1.6}vh);  
    left: auto;
    right: 0;
    bottom: 0;
    max-height: ${({ open }) => (open ? `calc(100vh - ${NAV_HEIGHT + LOGIN_HEIGHT}vh)` : '0')};
    overflow: hidden;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    
    transition: 
      transform 0.8s ease,
      max-height 0.8s ease;
    max-width: 50%;
     z-index: 1000; z-index: 1000;

  }
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Logo = styled.img`
  width: 5vw; /* Adjust width as needed */
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease; /* Added opacity transition */

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
  width: 19vw; /* Adjust width as needed */
  height: auto;
    order: 0;
    display: ${({ show }) => (show ? 'block' : 'none')};
    animation: ${fadeIn} 0.3s ease; /* Apply fade-in animation */
  }
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  gap: 2rem;
   flex-direction: row;

  li {
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: #007bff;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    padding: 0; /* Remove default padding */
  margin: 0;
  margin-top: 1.75vh;
  }
`;

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  gap: 2rem;

  &.active {
    color: #6F9600;
    border-bottom: 2px solid #fff;
    margin-top: 1vh;
  }

  &:hover {
    color: #6F9600;
  }
`;

const SearchBar = styled.input`
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  height: 50%;

  &:focus {
    background-color: #555;
  }

  @media (max-width: 768px) {
    width: 80%;
    margin-bottom: 1rem;
    height: 6vh;
    align-self: center;
  }
`;

const Button = styled.button`
display: none;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 60%;
    margin-right: 0;
    margin-bottom: 1rem;
    align-self: center;
  }
`;

const PhoneNumber = styled.div`
  color: #fff; /* Default color is white */
  margin-right: 1rem;
  transition: color 0.3s ease;
  text-align: center;
  display: flex;
  align-items: center; /* Center content vertically */
  gap: 0.5rem; /* Space between icon and text */

  &:hover {
    color: #fff; /* Keep the color white on hover */
  }

  a {
    color: #fff; /* Link color is white */
    text-decoration: none; /* Remove underline from link */
    transition: color 0.3s ease;

    &:hover {
      color: #fff; /* Keep the link color white on hover */
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 1.6rem;
    margin-bottom: 1rem;
    font-size: 0.75rem;
    text-align: center;
  }
`;


const MenuToggle = styled.div`
  display: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: relative;

  @media (max-width: 768px) {
    display: block;
    order: 2;
    margin-left: auto;
    
  }
`;

const Bar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #fff;
  position: absolute;
transition: transform 0.3s ease, opacity 0.7s ease, right 0.7s ease;

  &:nth-child(1) {
    top: ${({ open, show }) => (open || show ? '12px' : '0')};
    transform: ${({ open, show }) => (open || show ? 'rotate(-45deg)' : 'rotate(0)')};
  }

   &:nth-child(2) {
    top: 12px;
    right: ${({ open }) => (open ? '-170%' : '0')};
    
    opacity: ${({ open }) => (open ? '0' : '1')};
  }

  &:nth-child(3) {
    top: ${({ open, show }) => (open || show ? '12px' : '24px')};
    transform: ${({ open, show }) => (open || show ? 'rotate(45deg)' : 'rotate(0)')};
  }
`;



const FooterContainer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 1rem;
  height: 5vh;
  background-color: #808080;
  transition: transform 0.6s ease, opacity 0.6s ease;
  transform: translateY(${({ visible }) => (visible ? '0' : '100%')});
  opacity: ${({ visible }) => (visible ? '1' : '0')};
`;

const SearchIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  font-size: 1.5rem;
  opacity: 1; /* Initially visible */
  transition: transform 0.3s ease, opacity 2s ease; /* Added opacity transition */
display:none;
  @media (max-width: 768px) {
    display: none;
  }

  ${({ hide }) =>
    hide &&
    css`
      opacity: 0; /* Set opacity to 0 when hide is true */
      transition: opacity 0.3s ease; `}
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  
  @media (max-width: 768px) {
    ${({ isSearchActive }) => isSearchActive && 'flex-grow: 1;'}
  }
`;

const SearchInput = styled.input`
  width: 0;
  opacity: 0;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  transition: width 0.6s ease, opacity 0.3s ease;

  ${({ show }) =>
    show &&
    css`
      width: 100%; /* Adjust width as needed */
      opacity: 1;
      padding: 0.5rem 1rem; /* Adjust padding as needed */
      margin-left: 1rem; /* Optional: Add margin for spacing */
    `}
`;


const Navbar = ({ handleLogin, isLoggedIn, setIsLoggedIn, dispatch }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [hideIcon, setHideIcon] = useState(false);
  const [username, setUsername] = useState('User');
  const [activeLink, setActiveLink] = useState('');
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userUsername, setUserUsername] = useState(null);
  const cookies = new Cookies();
  const token = cookies.get("token");







  


  useEffect(() => {
    const token = cookies.get("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken.userId);
      setUserEmail(decodedToken.userEmail);
      setUserUsername(decodedToken.userUsername);
      dispatch({ type: "SET_LOGIN_STATE", payload: true });
    }
  }, [dispatch]);

  const handleLogout = () => {
    cookies.remove("token");
    dispatch({ type: "SET_LOGIN_STATE", payload: false });
    window.location.reload();
  };

  const decodedToken = token ? jwtDecode(token) : null;
  const adminToken = decodedToken ? decodedToken.userAdmin : false;

  useEffect(() => {
    const token = cookies.get('token');
    
  }, [token]);



  const handleLinkClick = (path) => {
    setActiveLink(path);

    setMenuOpen(false);
  };




  useEffect(() => {
    const handleScroll = () => {
      if (menuOpen && window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        setShowFooter(true);
      } else {
        setShowFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [menuOpen]);

  const toggleSearch = () => {
    setIsSearch(!isSearch);
    setHideIcon(true);
    setTimeout(() => {
      setHideIcon(false);
    }, 100);
  };

  const handleMenuToggleClick = () => {
    if (isSearch) {
      toggleSearch();
    } else {
      setMenuOpen(!menuOpen);
    }
  };

  return (
    <>
      <NavbarWrapper>
        <LoginComponent
          handleLogin={handleLogin} handleLogout={handleLogout} isLoggedIn={isLoggedIn} username={username} />
        <NavbarContainer>
          <Link to="/"> {/* Wrap Logo with Link */}
            <Logo src={logo} alt="Logo" show={!isSearch} onClick={() => handleLinkClick('/')} />
          </Link>
          <IconContainer isSearchActive={isSearch}>
            <MenuToggle onClick={handleMenuToggleClick}>
              <Bar open={menuOpen} show={isSearch} />
              <Bar open={menuOpen} show={isSearch} />
              <Bar open={menuOpen} show={isSearch} />
            </MenuToggle>
            <SearchIcon hide={hideIcon} show={isSearch} icon={faSearch} onClick={toggleSearch} />
            <SearchInput show={isSearch} placeholder="Search..." />
          </IconContainer>

          <NavbarContent open={menuOpen}>
            <NavLinks>
              <li>
                <StyledLink
                  to="/"
                  className={activeLink === '/' ? 'active' : ''}
                  onClick={() => handleLinkClick('/')}
                >
                  Home
                </StyledLink>
              </li>
              <li>
                <StyledLink
                  to="/aboutus"
                  className={activeLink === '/aboutus' ? 'active' : ''}
                  onClick={() => handleLinkClick('/aboutus')}
                >
                  About Us
                </StyledLink>
              </li>

              {adminToken && (<li>
                <StyledLink
                  to="/admin"
                  className={activeLink === '/admin' ? 'active' : ''}
                  onClick={() => handleLinkClick('/admin')}
                >
                  Admin
                </StyledLink>
              </li>)}
              <li>
                <StyledLink
                  to="/club"
                  className={activeLink === '/services' ? 'active' : ''}
                  onClick={() => handleLinkClick('/services')}
                >
                  Club
                </StyledLink>
              </li>
              <li>
                <StyledLink
                  to="/contact"
                  className={activeLink === '/contact' ? 'active' : ''}
                  onClick={() => handleLinkClick('/contact')}
                >
                  Contact
                </StyledLink>
              </li>
              {decodedToken && (
              <li>
                <StyledLink
                  to="/listings"
                  className={activeLink === '/listings' ? 'active' : ''}
                  onClick={() => handleLinkClick('/listings')}
                >
                  Listings
                </StyledLink>
              </li>)}
              <li>
                <StyledLink
                  to="/galleries"
                  className={activeLink === '/galleries' ? 'active' : ''}
                  onClick={() => handleLinkClick('/galleries')}
                >
                  Galleries
                </StyledLink>
              </li>
{userId && (
              <li>
                <StyledLink
                  to="/login"
                  className={activeLink === '/login' ? 'active' : ''}
                  onClick={() => handleLinkClick('/login')}
                >
                  Manage Account
                </StyledLink>
              </li>)}
            </NavLinks>

             <PhoneNumber>
                <FontAwesomeIcon icon={faEnvelope} />{" "}
                <a href="mailto:membership@seatonvalleyrc.club">membership@seatonvalleyrc.club</a>
                </PhoneNumber>
            <Button>Client Login</Button>
            <Button>Get a Quote</Button>
          </NavbarContent>
        </NavbarContainer>
        <FooterContainer visible={showFooter}>
          Footer Content
        </FooterContainer>
      </NavbarWrapper>
    </>
  );
};

export default Navbar;
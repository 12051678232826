import React, { useRef, useEffect } from 'react';
import '../styles/PhotoDetailsModal.scss';
import closeSymbol from '../assets/closeSymbol.svg';

const PhotoDetailsModal = ({ closeModalPhoto, modalPhoto }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModalPhoto();
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        closeModalPhoto();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [closeModalPhoto]);

  return (
    <div className="photo-details-modal">
      <div className="photo-details-modal__header">
        <button onClick={closeModalPhoto} className="photo-details-modal__close-button">
          <img src={closeSymbol} alt="close symbol" />
        </button>
      </div>
      <div className="photo-details-modal__content" ref={modalRef}>
        <img className="photo-details-modal__image" src={modalPhoto.url} alt="Photo" />
      </div>
    </div>
  );
};

export default PhotoDetailsModal;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Gallery from "../components/Gallery";
import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Galleries = ({ state, dispatch }) => {
  const navigate = useNavigate();

  // Fetch photos function
  const fetchPhotos = async (galleryId) => {
    const url = `${backendUrl}/photos/${galleryId}`;

    try {
      const response = await axios.get(url);
      dispatch({ type: "SET_PHOTO_DATA", payload: response.data });
      console.log("Route:", url);
      console.log("Fetch Photo Data:", response.data);
    } catch (error) {
      console.error("Error fetching photos:", error);
    }
  };

  useEffect(() => {
    fetchPhotos(state.gallerystate);
  }, [state.gallerystate, dispatch]);

  // Map galleries to Gallery components
  const galleryComponents = state.galleries.map((gallery) => (
    <Gallery
      key={gallery.id}
      galleryData={{
        imageSrc: gallery.image,
        title: gallery.name,
        description: gallery.description,
        id: gallery.id
      }}
      onClick={() => {
        console.log("Clicked gallery number:", gallery.id);
        dispatch({ type: "SET_GALLERYSTATE_DATA", payload: gallery.id });
        fetchPhotos(gallery.id);
        navigate(`/galleries/${gallery.id}`);
      }}
    />
  ));

  return (
    <div className="entiregallery">
      <div className="gallerycontainer">{galleryComponents}</div>
    </div>
  );
};

export default Galleries;

import React, { useState } from 'react';
import "../styles/listingitem.scss";
import Button from '@mui/material/Button'; // Import Button component from Material-UI
import axios from "axios";
import Cookies from "universal-cookie";
import { jwtDecode } from 'jwt-decode';
import NewListingModal from './NewListingModal';
const backendUrl = process.env.REACT_APP_BACKEND_URL
const cookies = new Cookies();



function Listing({ listing }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = cookies.get('token')
  const decodedToken = jwtDecode(token);
  const userID = decodedToken.userId



  const { id, name, email, imageurl, price, description, createdAt, updatedAt } = listing;

  const handleDelete = async () => {
    try {
      // Add your delete logic here
      console.log(`Deleting listing with ID: ${id} for user with ID: ${userID}`);
      const response = await axios.delete(`${backendUrl}/listings/${id}`, {
        data: {
          userID: userID,
          postID: id

        }
      });
      window.location.reload();
      // Handle response if necessary
    } catch (error) {
      // Handle error
      console.error("Error deleting listing:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      // Add your delete logic here
      console.log(`Updating listing with ID: ${id} for user with ID: ${userID}`);
      const response = await axios.delete(`${backendUrl}/listings/${id}`, {
        data: {
          userID: userID,
          postID: id

        }
      });
      window.location.reload();
      // Handle response if necessary
    } catch (error) {
      // Handle error
      console.error("Error deleting listing:", error);
    }
  };


  const handleUpdateListing = () => {
    setIsModalOpen(true); // Open the modal when Add Listing button is clicked
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="listing">
     {isModalOpen && (
        <NewListingModal
          onClose={handleCloseModal}
          listing={listing} // Pass the listing data to the modal for editing
        />
      )}
      <div className="listing-image">
        <img src={imageurl} alt={name} />
      </div>
      <div className="listing-details">
        <div className="listing-name">{name}</div>
        <div className="listing-info">
          <p>Description: {description}</p>
          <p>Price: ${price}</p>
          <p>Contact Info: {email}</p>
          <p>Created At: {createdAt}</p>
          <p>Updated At: {updatedAt}</p>
          
        </div>
        
      </div>
      
      
      <Button
  variant="contained"
  sx={{
    backgroundColor: 'orange',
    '&:hover': {
      backgroundColor: 'darkorange', // Adjust the hover color as needed
    },
  }}
  onClick={handleUpdateListing}
>
  Update
</Button>
      <Button variant="contained" color="error" onClick={handleDelete}>Delete</Button>
    </div>
  );
}

export default Listing;
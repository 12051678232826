import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Cookies from "universal-cookie";
import { jwtDecode } from "jwt-decode";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const LOGIN_HEIGHT = 4;

const LoginWrapper = styled.div`
  display: flex;
  justify-content: space-between; /* Aligns items to the ends */
  align-items: center;
  max-width: 100%;
  padding: 10px;
  background-color: #666;
  color: white;
  height: ${LOGIN_HEIGHT}vh;  // Set the height here
  position: relative;  // Ensure it appears on top of other elements
@media (max-width: 768px) {
    max-width: 100%;
  }
  `;

const expandWidth = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;  /* Expand to 100% width */
  }
`;

const shrinkWidth = keyframes`
  from {
    width: 100%;  /* Start with 100% width */
  }
  to {
    width: 0;
  }
`;

const UsernameBase = styled.div`
  font-size: 1em;
  transition: all 0.5s ease;
  opacity: ${({ isLoggedIn }) => (isLoggedIn ? '1' : '0')};
  animation: ${({ isLoggedIn }) => (isLoggedIn ? expandWidth : shrinkWidth)} 0.5s forwards;
`;

const Username = styled(UsernameBase)`
  width: ${({ isLoggedIn }) => (isLoggedIn ? 'auto' : '0')}; /* Auto width when logged in */
  margin-right: 1em; /* Adds space between username and login/logout buttons */
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: start;
  width: ${({ showInputs }) => (showInputs ? '100%' : '0')};
  overflow: ${({ showInputs }) => (showInputs ? 'visible' : 'hidden')}; /* Hide overflow only when showInputs is false */
  margin-left: ${({ showInputs }) => (showInputs ? '1.5vw' : '0')};
  margin-right: ${({ showInputs }) => (showInputs ? '1vw' : '0')};

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    width: ${({ showInputs }) => (showInputs ? 'auto' : '0')};
    margin-left: ${({ showInputs }) => (showInputs ? '10px' : '0')}; 
    overflow: ${({ showInputs }) => (showInputs ? 'visible' : 'hidden')}; /* Hide overflow only when showInputs is false */
    animation: ${({ showInputs }) => (showInputs ? expandWidth : shrinkWidth)} 0.3s forwards;
  }

  @media (min-width: 769px) {
    
    margin-left: 0;
    margin-right: 0;
  }
`;



const InputField = styled.input`
padding: 10px;
width: ${({ showInputs }) => (showInputs ? '47%' : '0px')}; 
  transition: width 0.3s ease; /* Smooth transition */
  border-radius: ${({ isLoggedIn }) => (isLoggedIn ? '50%' : '5px')}; 
margin: 5px;




@media (max-width: 768px) {
  padding: 5px;
  margin: 5px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: ${({ showInputs }) => (showInputs ? '100px' : '0px')}; /* Adjusted width */
  transition: width 0.3s ease; /* Smooth transition */
}
`;

const ButtonBase = styled.button`
  background-color: #555;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-size: 1em;
  transition: all 0.5s ease;
`;

const LoginButton = styled(ButtonBase)`
  width: ${({ isLoggedIn }) => (isLoggedIn ? '50px' : '25%')}; 
  opacity: ${({ isLoggedIn }) => (isLoggedIn ? '0' : '1')};
  overflow: hidden;
  animation: ${({ isLoggedIn }) => (isLoggedIn ? shrinkWidth : expandWidth)} 0.5s forwards;
  background-color: #6F9600;
  margin-right: auto; 
  border-radius: ${({ isLoggedIn }) => (isLoggedIn ? '50%' : '5px')}; 

  @media (min-width: 769px) {
    width: 25%; /* Set the width to 25% on desktop screens */
  }
`;


const LogoutButton = styled(ButtonBase)`
  background-color: red;
  width: ${({ isLoggedIn }) => (isLoggedIn ? '100px' : '0')};
  opacity: ${({ isLoggedIn }) => (isLoggedIn ? '1' : '0')};
  overflow: hidden;
  animation: ${({ isLoggedIn }) => (isLoggedIn ? expandWidth : shrinkWidth)} 0.5s forwards;
  margin-left: auto; /* Pushes the button to the far right */
  border-radius: ${({ isLoggedIn }) => (isLoggedIn ? '5px' : '50%')}; /* Adjust the border-radius property */
  
  @media (min-width: 769px) {
    margin-right: 20px; /* Add room to the right only on desktop screens */
  }
`;



export const LoginComponent = ({  username }) => {
  const [showInputs, setShowInputs] = useState(false);
  const [credentials, setCredentials] = useState({ username: "", password: "" });
  const [error, setError] = useState('');
  const cookies = new Cookies();
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [token, setToken] = useState(cookies.get('token') || null);

  

  const handleLoginClick = () => {
    if (showInputs && credentials.username && credentials.password) {
      handleSubmit();
      setIsLoggedIn(true)
      setShowInputs(false);
      
    } else {
      setShowInputs(true);
    }
  };

  const handleLogout = () => {
    cookies.remove("token");
    
    
  };

  const handleLogoutClick = () => {
    setShowInputs(false);
    setIsLoggedIn(false)
    handleLogout();
    setToken(null);
    
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      console.log('Sending credentials:', {
        email: credentials.username,
        password: credentials.password
      });
      const response = await fetch(`${backendUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: credentials.username,
          password: credentials.password
        }),
      });

      if (response.ok) {
        const data = await response.json();
        // Assuming cookies and navigate are properly imported and initialized
        cookies.set('token', data.token, { path: '/' }); 
        setToken(data.token);
        
      } else {
        const errorMessage = await response.json();
        setError(errorMessage.message);
        // Display error message to the user
        alert(errorMessage.message);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to log in. Please try again later.'); // Display an alert if an error occurs
    }
  };

 

  useEffect(() => {
    const token = cookies.get('token');
    setIsLoggedIn(Boolean(token));
  }, [token]);

  const decodedToken = token ? jwtDecode(token) : null;
  const adminToken = decodedToken ? decodedToken.userAdmin : false;

  return (
    <LoginWrapper>
      {decodedToken &&
      <Username isLoggedIn={isLoggedIn}>Logged in as {decodedToken.userUsername}</Username>}
      {!token &&
      <LoginButton isLoggedIn={isLoggedIn} onClick={handleLoginClick}>
        {!isLoggedIn ? 'Login' : 'Submit'}
      </LoginButton>}

{!token &&
      <InputsWrapper showInputs={showInputs}>
        <InputField
          type="text"
          name="username"
          value={credentials.username}
          onChange={handleInputChange}
          placeholder="Username"
          showInputs={showInputs}
        />
        <InputField
          type="password"
          name="password"
          value={credentials.password}
          onChange={handleInputChange}
          placeholder="Password"
          showInputs={showInputs}
        />
      </InputsWrapper>}


      {token &&
      <LogoutButton isLoggedIn={isLoggedIn} onClick={handleLogoutClick}>
        Logout
      </LogoutButton>
      }
    </LoginWrapper>
  );
};

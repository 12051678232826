import "../styles/login.scss";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { jwtDecode } from 'jwt-decode';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import backgroundImage1 from '../assets/photo-78.jpg';
import backgroundImage2 from '../assets/photo-83.jpg';
import backgroundImage3 from '../assets/photo-94.jpg';
import backgroundImage4 from '../assets/photo-75.jpg';
import backgroundImage5 from '../assets/photo-99.jpg';
import backgroundImage6 from '../assets/photo-82.jpg';
import backgroundImage7 from '../assets/photo-88.jpg';
import backgroundImage8 from '../assets/photo-92.jpg';
import backgroundImage9 from '../assets/photo-77.jpg';
import backgroundImage10 from '../assets/photo-79.jpg';
import backgroundImage11 from '../assets/photo-84.jpg';
import backgroundImage12 from '../assets/photo-85.jpg';
import backgroundImage13 from '../assets/photo-86.jpg';
import backgroundImage14 from '../assets/photo-98.jpg';

import Cookies from "universal-cookie";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        MUI component reworked
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function LoginPage(props) {
  

  const { dispatch, state } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');
  const cookies = new Cookies();
const navigate = useNavigate();

const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const images = [
    backgroundImage1,
    backgroundImage2,
    backgroundImage3,
    backgroundImage4,
    backgroundImage5,
    backgroundImage6,
    backgroundImage7,
    backgroundImage8,
    backgroundImage9,
    backgroundImage10,
    backgroundImage11,
    backgroundImage12,
    backgroundImage13,
    backgroundImage14,
  ];

  useEffect(() => {

    // Function to set a random starting image index
    const setRandomImageIndex = () => {
      const randomIndex = Math.floor(Math.random() * images.length);
      setCurrentImageIndex(randomIndex);
    };

    // Set a random starting image index initially
    setRandomImageIndex();

    const interval = setInterval(() => {
      setFade(true);
      // Increment the current image index, reset to 0 if it reaches the end
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      // Delay the fade out effect slightly before resetting to false
      setTimeout(() => setFade(false), 500);
    }, 7500); // Change image every 7.5 seconds (7500 milliseconds)

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);

  const currentImageUrl = images[currentImageIndex];







const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    console.log('Posting to URL:', `${backendUrl}/login`);
    console.log('Request body:', { email, password });
    const response = await fetch(`${backendUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (response.ok) {
      const data = await response.json();
      cookies.set('token', data.token, { path: '/' }); // Set token cookie with HttpOnly flag
      navigate('/');
      console.log(data);
      dispatch({ type: "SET_LOGIN_STATE", payload: true });
      window.location.reload();
    } else {
      const errorMessage = await response.json();
      setError(errorMessage.message);
      // Display error message to the user
      alert(errorMessage.message);
    }
  } catch (error) {
    console.error('Error:', error);
    alert('Failed to log in. Please try again later.'); // Display an alert if an error occurs
  }
};

  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newId, setNewId] = useState("");
  const [newUsername, setNewUsername] = useState("");

  const handleLogout = () => {
    // Dispatch an action to reset the login state
    dispatch({ type: "SET_LOGIN_STATE" });
    cookies.remove("user_id");
    cookies.remove("email");
    cookies.remove("user_name");
  };

  const handleChangeEmail = async () => {
    try {
      const tokenCookie = cookies.get('token')
      const decodedCookie = jwtDecode(tokenCookie);
      const user_id = decodedCookie.userId

      // Make an HTTP request to change the email
      const response = await axios.put(`${backendUrl}/login`, {
        email: newEmail, id: user_id
      });

      alert("Email changed successfully!");
      console.log("userid",user_id)
      console.log("email", newEmail)
    } catch (error) {
      console.error("Error changing email:", error);
      alert("Failed to change email. Please try again.");
    }
  };

  const handleChangeUsername = async () => {
    try {
      const tokenCookie = cookies.get('token')
      const decodedCookie = jwtDecode(tokenCookie);
      const user_id = decodedCookie.userId
      

      // Make an HTTP request to change the email
      const response = await axios.put(`${backendUrl}/login`, {
        username: newUsername, id: user_id
      });

      alert("Username changed successfully!");
    } catch (error) {
      console.error("Error changing email:", error);
      alert("Failed to change email. Please try again.");
    }
  };

  const handleChangePassword = async () => {
    try {

      const tokenCookie = cookies.get('token')
      const decodedCookie = jwtDecode(tokenCookie);
      const user_id = decodedCookie.userId

      // Make an HTTP request to change the password
      const response = await axios.put(`${backendUrl}/login`, {
        password: newPassword, id: user_id
      });

      alert("Password changed successfully!");
    } catch (error) {
      console.error("Error changing password:", error);
      alert("Failed to change password. Please try again.");
    }
  };


  const handleDelete = async () => {
    try {
      const user_id = cookies.get("user_id");

      // Make an HTTP request to delete the user account
      const response = await axios.delete(`${backendUrl}/register`, {
        data: { id: user_id } // Include the user ID in the request body
      });

      alert("User deleted successfully!");
      handleLogout(); // Log out the user after deleting the account
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("Failed to delete user. Please try again.");
    }
  };













  const user_id = cookies.get("user_id");
  const user_email = cookies.get("email");
  const token = cookies.get('token')
  

  if (!token) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: '100vh' }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(${currentImageUrl})`,
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'background-image 2s ease-in-out',
              
            }}
          />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '12vh',
                
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, backgroundColor: '#6F9600', color: '#FFFFFF' }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>

                  </Grid>
                  <Grid item>
                    <Link href="/sign-up" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>

    );
  } else {
    return (
      <ThemeProvider theme={defaultTheme}>
        <Grid container component="main" sx={{ height: '100vh' }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(${currentImageUrl})`,
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              transition: 'background-image 2s ease-in-out',
            }}
          />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '12vh',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Manage your account
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Enter a new email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  type="email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  placeholder="Enter new email"
                />
                <Button
                  onClick={handleChangeEmail}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, backgroundColor: '#6F9600', color: '#FFFFFF' }}

                >Change Email</Button>

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Enter a new username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  type="text"
                  value={newUsername}
                  onChange={(e) => setNewUsername(e.target.value)}
                  placeholder="Enter new username"
                />
                <Button
                  onClick={handleChangeUsername}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, backgroundColor: '#6F9600', color: '#FFFFFF' }}

                >Change Username</Button>

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Enter a new password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <Button
                  onClick={handleChangePassword}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, backgroundColor: '#6F9600', color: '#FFFFFF' }}
                >Change Password</Button>
                


                <Grid container>
                  <Grid item xs>

                  </Grid>
                  <Grid item>

                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>);
  }
}

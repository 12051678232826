import React, { useState } from 'react';
import PostModal from './PostModal';
import '../styles/PostPage.scss';

const PostItem = ({ photo, name, description, first_name, last_name, username, date }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(null);

  const post = { photo, name, description, first_name, last_name, username, date };
  return (
    <div  onClick={handleOpen} className="post-container">
      <div className="post-info">
        <h2>{name}</h2>
        <p>{description}</p>
        <p className="author-info">
          <strong>Author:</strong> {first_name} {last_name} ({username})
        </p>
        <p className="date-info">
          <strong>Date Updated:</strong> {new Date(date).toLocaleString()}
        </p>
      </div>
      <img src={photo} alt="Post" className="post-photo" />
      <PostModal open={open} handleClose={handleClose} post={post} />
      
    </div>
  );
};

export default PostItem;